@import "libs/normalize";
@import "partials/functions";
@import "partials/mixins";
@import "partials/variables";

@include font-face("DukeFill", $font-path);
@include font-face("TradeGothic", $font-path);
@include font-face("Custom", $font-path);

[class^="icon-"],
[class*=" icon-"] {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: "Custom";
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	line-height: 1;
	speak: none;
	text-transform: none;
}

.icon-twitter:before {
	content: "\f099";
}

.icon-facebook:before {
	content: "\f09a";
}

.icon-instagram:before {
	content: "\f16d";
}

.icon-youtube-play:before {
	content: "\f16a";
}

body {
	color: #fff;
	font-family: TradeGothic, Arial, serif;
	min-width: 320px;
}

a {
	text-decoration: none;
	transition: background .25s ease, color .25s ease;
}

button {
	background: $color-gold;
	border: 0;
	color: $color-purple;
	cursor: pointer;
	font: 24px/24px DukeFill, Arial, serif;
	margin: 0;
	outline: 0;
	padding: 15px 40px;
	text-align: center;
	text-transform: uppercase;
	transition: background .25s ease;

	&:active {
		background: #fff;
	}
}

.ripple {
	backface-visibility: hidden;
	cursor: pointer;
	display: inline-block;
	position: relative;
	transform: translateZ(0);
	vertical-align: middle;

	&:before {
		animation-duration: 1s;
		border: 6px solid $color-gold;
		bottom: 0;
		content: "";
		left: 0;
		opacity: 0;
		position: absolute;
		right: 0;
		top: 0;
	}

	&:hover:before {
		animation-name: ripple;
	}
}

@keyframes ripple {
	0% {
		border: 6px solid $color-gold;
		opacity: 1;
	}

	100% {
		bottom: -12px;
		left: -12px;
		opacity: 0;
		right: -12px;
		top: -12px;
	}
}


// Header
.header-wrapper {
	background: $color-purple-lighter;
	position: relative;
	width: 100%;

	&:before,
	&:after {
		background: url($image-path + "stars.png") no-repeat;
		bottom: 0;
		content: "";
		height: 214px;
		position: absolute;
		width: 207px;
	}

	&:before {
		background-position: 0 0;
		left: 0;
	}

	&:after {
		background-position: 0 -214px;
		right: 0;
	}
}

.header-wrapper__background {
	background: url($image-path + "header-hero-background.jpg") no-repeat;
	background-position: top center;
	background-size: cover;
	height: 407px;
	margin: 0 auto;
	position: relative;

	&:before {
		background-image: linear-gradient(to bottom, transparent 50%, $color-purple-lighter 100%);
		bottom: 0;
		content: "";
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
	}
}

.header {
	margin: 0 auto;
	max-width: 920px;
	padding: 0 20px 55px;
	width: 100%;

	&--adjust {
		padding-bottom: 110px;
	}
}

.racing-with-stars {
	display: block;
	height: auto;
	margin: 0 auto;
	max-width: 100%;
	position: relative;
}

.header__header {
	font: 35px/43px DukeFill, Arial, serif;
	margin-top: 165px;
	text-align: center;
}

.header__copy {
	font: 18px/22px TradeGothic, Arial, serif;
	text-align: center;

	a {
		color: $color-gold;

		&:hover {
			color: #ffcc3c;
		}

		&:active {
			color: #fff;
		}
	}
}

.header__button__wrapper {
	margin-top: 30px;
	text-align: center;
}


// How It Works
.how-it-works-wrapper {
	margin: 0 auto;
	max-width: 1440px;
}

.how-it-works__banner {
	display: block;
	height: auto;
	margin: 65px auto 0;
	max-width: 70%;
}

.how-it-works {
	height: 480px;
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.how-it-works__item {
	float: left;
	height: auto;
	margin: 0 0 150px;
	min-width: 185px;
	padding: 0 60px;
	text-align: center;
	width: calc(33.3333% - 120px);
}

.how-it-works__item__image {
	height: 192px;
	margin: 25px auto 0;
	width: 192px;
}

.how-it-works__item__title {
	color: $color-purple;
	font: 36px/36px DukeFill, Arial, serif;
	margin: 40px 0 0;
	padding: 0;
	text-transform: uppercase;
}

.how-it-works__item__copy {
	color: #535355;
	font: 16px/22px TradeGothic, Arial, serif;
}


// VIP Experiences
.vip-experiences {
	background: $color-purple;
	display: table;
	height: 700px;
	list-style-type: none;
	margin: 0;
	padding: 0;
	table-layout: fixed;
	width: 100%;

	&:hover .vip-experiences__item,
	&.active .vip-experiences__item {
		width: 12%;
	}

	&:hover .vip-experiences__item:hover,
	&.active .vip-experiences__item.active {
		width: 64%;
	}
}

.vip-experiences-wrapper {
	clear: left;
	position: relative;
}

.vip-experiences__banner {
	display: block;
	height: auto;
	left: 0;
	margin: -105px auto auto;
	max-width: 70%;
	pointer-events: none;
	position: absolute;
	right: 0;
	z-index: 2;
}

.vip-experiences__item {
	display: table-cell;
	margin: 0;
	max-height: 100%;
	overflow: hidden;
	padding: 0;
	position: relative;
	text-align: center;
	transition: width .5s ease;
	width: 25%;

	&:before {
		bottom: 0;
		content: "";
		display: block;
		height: 110px;
		position: absolute;
		width: 110px;
	}

	&:hover .vip-experiences__item__title,
	&.active .vip-experiences__item__title {
		font: 56px/52px DukeFill, Arial, serif;
		margin-top: 120px;
		right: 0;
		transition: font .25s ease .5s, margin-top .5s ease .5s, right .25s ease, width .25s ease;
		width: 65%;
	}

	&.active .vip-experiences__item__copy,
	&:hover .vip-experiences__item__copy {
		color: $color-gold;
		display: block;
		font: 22px/22px DukeFill, Arial, serif;
		margin-top: 230px;
		position: absolute;
		right: 0;
		transition: margin-top .5s ease .5s, right .25s ease, width .25s ease;
		width: 65%;
	}

	&.active .vip-experiences__item__info,
	&:hover .vip-experiences__item__info {
		opacity: 1;
		top: 0;
		transition: opacity .5s ease .5s, top .5s ease .5s;
	}

	&.active .vip-experiences__item__info button,
	&:hover .vip-experiences__item__info button {
		display: block;
	}
}

.racing-experience {
	background: url($image-path + "racing-experience-bob-baffert-and-mike-smith.jpg") no-repeat;
	background-position: 25% center;
	background-size: cover;

	&:before {
		background: url($image-path + "stars.png") no-repeat 0 -428px;
		left: 0;
	}
}

.culinary-experience {
	background: url($image-path + "culinary-experience-bobby-flay.jpg") no-repeat;
	background-position: 25% center;
	background-size: cover;

	&:before {
		background: url($image-path + "stars.png") no-repeat 0 -428px;
		left: 0;
	}
}

.carpet-experience {
	background: url($image-path + "purple-carpet-experience-celebrity-ambassador.jpg") no-repeat;
	background-position: 25% center;
	background-size: cover;

	&:before {
		background: url($image-path + "stars.png") no-repeat -110px -428px;
		right: 0;
	}
}

.tv-golf-experience {
	background: url($image-path + "tv-golf-experience-nbc-sports.jpg") no-repeat;
	background-position: 25% center;
	background-size: cover;

	&:before {
		background: url($image-path + "stars.png") no-repeat -110px -428px;
		right: 0;
	}
}

.vip-experiences__item__title {
	font: 42px/42px DukeFill, Arial, serif;
	margin: 450px 0 7px;
	min-width: 240px;
	position: absolute;
	text-transform: uppercase;
	width: 100%;
}

.vip-experiences__item__copy {
	color: $color-gold;
	font: 22px/22px DukeFill, Arial, serif;
	margin-top: 540px;
	min-width: 240px;
	position: absolute;
	width: 100%;
}

.vip-experiences__item__info {
	font: 16px/22px TradeGothic, Arial, serif;
	height: 100%;
	opacity: 0;
	position: absolute;
	right: 0;
	top: 700px;
	width: 65%;

	ol {
		list-style-type: none;
		margin: 300px 30px 0 0;
	}

	li {
		margin-bottom: 15px;
		text-align: left;

		&:before {
			background: url($image-path + "stars.png") no-repeat -207px -415px;
			content: "";
			height: 13px;
			margin: 3px 0 0 -20px;
			position: absolute;
			width: 13px;
		}
	}
}

.vip-experiences__item__button-enter-to-win {
	display: none;
	left: 50%;
	margin: 10px 0 30px -82px;
	position: absolute;
	width: 164px;
}


// Footer
.footer-wrapper {
	background: $color-purple;
	border-top: 6px solid $color-gold;
	float: left;
	margin: 0;
	width: 100%;
}

.footer {
	margin: 0 auto;
	max-width: 1440px;
	padding: 53px 80px 98px;
}

.footer__navigation {
	float: left;
	list-style-type: none;
	margin: 0;
	padding: 14px 0 52px;
	width: 70%;
}

.footer__navigation__item {
	display: inline-block;
	float: left;
	font: 24px/24px DukeFill, Arial, serif;
	text-decoration: none;

	&:after {
		content: "|";
		padding: 0 30px;
	}

	&:last-child:after {
		content: "";
		padding: 0;
	}

	a {
		color: $color-gold;
		letter-spacing: .25px;
		text-decoration: none;
		text-transform: uppercase;

		&:hover {
			color: #ffcc3c;
		}

		&:active {
			color: #fff;
		}
	}
}

.footer__social-media {
	float: right;
	list-style-type: none;
	margin: 0;
	padding: 0;
	width: 30%;
}

.footer__social-media__item {
	display: inline-block;
	float: right;

	a {
		background: $color-gold;
		border-radius: 52px;
		color: $color-purple;
		display: block;
		font-size: 30px;
		height: 52px;
		line-height: 52px;
		margin-left: 10px;
		text-align: center;
		text-decoration: none;
		width: 52px;

		&:hover {
			background: #ffcc3c;
		}

		&:active {
			background: #fff;
		}

		span {
			display: none;
			overflow: hidden;
			text-indent: -9999px;
		}
	}
}

.footer__rules {
	clear: left;
}

.footer__rules__header {
	font: 24px/30px DukeFill, Arial, serif;
	letter-spacing: .25px;
	margin: 0 0 11px;
	padding: 0;
	text-transform: uppercase;
}

.footer__rules__copy {
	font: 14px/20px TradeGothic, Arial, serif;
	width: auto;

	a {
		color: $color-gold;
		text-decoration: none;

		&:hover {
			color: #ffcc3c;
		}

		&:active {
			color: #fff;
		}
	}
}


.skin {
	background: $color-purple;

	&:before,
	&:after {
		display: none;
	}

	.header-wrapper {
		&:before,
		&:after {
			display: none;
		}
	}

	.header-wrapper__background {
		background: url($image-path + "header-skin.jpg") no-repeat;
		background-position: bottom center;
		background-size: cover;
		margin: 0 auto;
		max-height: 340px;
		position: relative;
	}

	.racing-with-stars {
		display: block;
		height: auto;
		margin: 0 auto;
		max-height: 95%;
		max-width: 100%;
		position: relative;
		top: 0;
	}
}

.copy-wrapper,
.iframe-wrapper {
	background: linear-gradient(to bottom, $color-purple-lighter 0%, $color-purple 100%);
}

.copy-wrapper {
	border: 0;
	display: block;
	margin: 0 auto;
	min-height: 750px;
	padding: 0;

	.copy {
		margin: 0 auto;
		max-width: 1440px;
		padding: 40px;
	}

	h1 {
		font-family: DukeFill, Arial, serif;
		font-size: 56px;
		font-weight: lighter;
		margin: 0 0 20px;
		padding: 0;
	}

	h2 {
		font-family: DukeFill, Arial, serif;
		font-size: 36px;
		font-weight: lighter;
		margin: 40px 0 10px;
		text-transform: uppercase;
	}

	h4 {
		font-size: 18px;
		font-weight: bold;
		margin: 20px 0 10px;
		text-transform: uppercase;
	}

	p {
		margin: 0 0 10px;
	}

	a {
		color: $color-gold;
		overflow-wrap: break-word;
		text-decoration: none;
		word-wrap: break-word;

		&:hover {
			color: #ffcc3c;
		}

		&:active {
			color: #fff;
		}
	}

	ol,
	ul {
		li {
			margin-bottom: 10px;
		}
	}
}

.rules__navigation {
	float: left;
	list-style-type: none;
	margin: 0;
	padding: 14px 0 52px;
	width: 100%;
}

.rules__navigation__item {
	display: inline-block;
	float: left;
	font: 24px/24px DukeFill, Arial, serif;
	text-decoration: none;

	&:after {
		content: "|";
		padding: 0 30px;
	}

	&:last-child:after {
		content: "";
		padding: 0;
	}

	a {
		color: $color-gold;
		letter-spacing: .25px;
		text-decoration: none;
		text-transform: uppercase;

		&:hover {
			color: #ffcc3c;
		}

		&:active {
			color: #fff;
		}
	}
}

.iframe-wrapper {
	iframe {
		background: transparent;
		border: 0;
		display: block;
		margin: 0 auto;
		min-height: 500px;
		padding: 0;
	}
}

.vip-experiences__item.tv-golf-experience .vip-experiences__item__info ol {
	margin-top: 270px;
}

@media screen and (min-width: 2000px) {
	.header-wrapper__background {
		background: url($image-path + "header-hero-background@2x.jpg") no-repeat;
		background-size: cover;
	}
}

@media (max-width: 1280px) {
	.header {
		max-width: 640px;
		padding-left: 40px;
		padding-right: 40px;
	}

	.footer {
		padding-left: 40px;
		padding-right: 40px;
	}

	.footer__navigation__item:after,
	.rules__navigation__item:after {
		padding: 0 10px;
	}
}

@media (max-width: 960px) {
	.header-wrapper {
		&:before {
			background: url($image-path + "stars.png") no-repeat 0 -428px;
			height: 100px;
			width: 110px;
		}

		&:after {
			background: url($image-path + "stars.png") no-repeat -110px -428px;
			height: 100px;
			width: 110px;
		}
	}

	.header-wrapper__background {
		height: auto !important;
	}

	.header {
		padding-bottom: 50px;
		width: auto;
	}

	.header__header {
		font-size: 28px;
		line-height: 30px;
		margin-top: 0;
	}

	.header__copy {
		font-size: 14px;
		line-height: 20px;
	}

	.how-it-works__banner {
		margin-top: 35px;
	}

	.how-it-works {
		height: auto;
		margin-top: 30px;
	}

	.how-it-works__item {
		margin-bottom: 20px;
		padding-left: 40px;
		padding-right: 40px;
		width: calc(100% - 80px);
	}

	.how-it-works__item__image {
		background-repeat: no-repeat;
		background-size: cover;
		float: left;
		height: auto;
		margin: 0 10px 0 0;
		max-width: 100%;
		width: calc(25% - 10px);
	}

	.how-it-works__item__title {
		float: left;
		font-size: 28px;
		line-height: 30px;
		text-align: left;
		width: 75%;
	}

	.how-it-works__item__copy {
		float: left;
		font-size: 14px;
		line-height: 20px;
		text-align: left;
		width: 75%;
	}

	.vip-experiences__banner {
		margin: 10px auto 0;
		position: relative;
	}

	.vip-experiences__item {
		border-bottom: 2px solid #fff;
		cursor: pointer;
		float: left;
		min-height: 175px;
		transition: none;
		width: 100% !important;

		&:last-child {
			border: 0;
		}

		&:before {
			display: none;
		}

		&.active:before {
			background: linear-gradient(to left, transparent 0%, rgba(60, 31, 84, .70) 0);
			content: "";
			display: block;
			height: 900px;
			overflow: hidden;
			position: absolute;
			top: 0;
			width: 100%;
		}

		&:hover .vip-experiences__item__title {
			font-size: 28px;
			line-height: 30px;
			margin: 20px 0 0;
			right: auto;
			width: 25%;
		}

		&.active .vip-experiences__item__title {
			font-size: 28px !important;
			left: 0 !important;
			line-height: 30px !important;
			margin-top: 20px !important;
			width: 25% !important;
		}

		&:hover .vip-experiences__item__copy {
			font-size: 20px !important;
			line-height: 20px !important;
			margin-top: 90px;
			right: auto !important;
			width: 25%;
		}


		&.active .vip-experiences__item__copy {
			left: 0 !important;
			margin-top: 90px !important;
			width: 25% !important;
		}

		&.active .vip-experiences__item__copy:after {
			border-color: transparent transparent $color-gold;
			border-style: solid;
			border-width: 0 13.5px 13px;
			height: 0;
			width: 0;
		}

		&.active .vip-experiences__item__info {
			transition: opacity .5s ease, top .5s ease;
			width: 100%;
		}
	}

	.vip-experiences.active .vip-experiences__item.active {
		min-height: 620px;
		transition: min-height .5s ease;
		width: 100% !important;
	}

	.racing-experience {
		background: url($image-path + "racing-experience-bob-baffert-and-mike-smith-alt.jpg") no-repeat;
		background-position: center 35%;
		background-size: cover;
	}

	.culinary-experience {
		background: url($image-path + "culinary-experience-bobby-flay-alt.jpg") no-repeat;
		background-position: center 25%;
		background-size: cover;
	}

	.carpet-experience {
		background: url($image-path + "purple-carpet-experience-celebrity-ambassador-alt.jpg") no-repeat;
		background-position: center 25%;
		background-size: cover;
	}

	.tv-golf-experience {
		background: url($image-path + "tv-golf-experience-nbc-sports-alt.jpg") no-repeat;
		background-position: center 25%;
		background-size: cover;
	}

	.vip-experiences__item__title {
		font-size: 28px;
		line-height: 30px;
		margin: 20px 0 0;
		width: 25%;
	}

	.vip-experiences__item__copy {
		font-size: 20px !important;
		line-height: 20px !important;
		margin-top: 90px;
		width: 25%;

		&:after {
			border-color: $color-gold transparent transparent;
			border-style: solid;
			border-width: 13px 13.5px 0;
			content: "";
			display: block;
			height: 0;
			left: 50%;
			margin: 13px 0 0 -13.5px;
			position: absolute;
			width: 0;
		}
	}

	.vip-experiences__item__info ol {
		margin-top: 170px;
	}

	.vip-experiences__item__info li {
		font-size: 14px !important;
		line-height: 20px !important;
	}

	.vip-experiences__item__button-enter-to-win {
		bottom: auto;
		left: auto;
		margin: 50px 0 0 20px;
		position: relative;
		width: auto;
	}

	.footer {
		padding-bottom: 60px;
	}

	.footer__navigation,
	.rules__navigation {
		padding-bottom: 10px;
		width: 100%;
	}

	.rules__navigation {
		margin: 14px 0 30px;
	}

	.footer__navigation__item,
	.rules__navigation__item {
		display: block;
		float: none;
		margin-bottom: 20px;
		text-align: center;

		&:after {
			display: none;
		}
	}

	.footer__social-media {
		float: none;
		text-align: center;
		width: 100%;
	}

	.footer__social-media__item {
		float: none;

		&:first-child a {
			margin-left: 0;
		}
	}

	.footer__rules__header {
		margin-top: 35px;
		text-align: center;
	}

	.vip-experiences__item.culinary-experience .vip-experiences__item__info ol {
		margin-top: 150px;
	}

	.vip-experiences__item.tv-golf-experience .vip-experiences__item__info ol {
		margin-top: 150px;
	}
}

@media (max-width: 480px) {
	.header-wrapper {
		&:before,
		&:after {
			height: 83px;
			width: 51px;
		}

		&:before {
			background: url($image-path + "stars.png") no-repeat 0 -538px;
		}

		&:after {
			background: url($image-path + "stars.png") no-repeat -51px -538px;
		}
	}

	.header {
		padding-left: 20px;
		padding-right: 20px;
	}

	.how-it-works__item {
		padding-left: 20px;
		padding-right: 20px;
		width: calc(100% - 40px);
	}

	.racing-experience {
		background-position: 75% 35%;
	}

	.culinary-experience {
		background-position: 75% 25%;
	}

	.carpet-experience {
		background-position: 75% 25%;
	}

	.tv-golf-experience {
		background-position: 75% 25%;
	}

	.how-it-works__item__title {
		margin-top: 0;
		width: auto;
	}

	.how-it-works__item__copy {
		width: calc(100% - 110px);
	}

	.copy-wrapper {
		.copy {
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	.footer {
		padding-left: 20px;
		padding-right: 20px;
	}
}
