// Font path.
$font-path: "../../fonts/";

// Image path.
$image-path: "../../images/build/";


$color-purple: #3c1f54;
$color-purple-lighter: #512d6b;
$color-gold: #f1b61b;
