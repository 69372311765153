// Usage: @include font-face("fontName", "/path/fonts/fontName");
@mixin font-face($family, $font-path, $weight: normal, $style: normal){
	@font-face{
		font-family: $family;
		src: url("#{$font-path}#{$family}.eot");
		src: url("#{$font-path}#{$family}.eot?#iefix") format("embedded-opentype"),
			url("#{$font-path}#{$family}.woff") format("woff"),
			url("#{$font-path}#{$family}.ttf") format("truetype"),
			url("#{$font-path}#{$family}.svg##{$family}") format("svg");
		font-style: $style;
		font-weight: $weight;
	}
}

// Usage: @include font-size(16);
@mixin font-size($value: 1.6){
	font-size: $value + rem;
}

// Usage: @include visually-hidden();
@mixin visually-hidden(){
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}
